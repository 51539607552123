var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "info"
  }, [_vm._m(0), _c('div', {
    staticClass: "info_name"
  }, [_vm._v("账号 - " + _vm._s(_vm.companyUser.userName))]), _c('dl', {
    staticClass: "info_dl"
  }, [_c('dd', [_vm._v("供应商名称:"), _c('span', [_vm._v(_vm._s(_vm.company.compName))])]), _c('dd', [_vm._v("简称:"), _c('span', [_vm._v(_vm._s(_vm.company.shortName))])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "info_img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/Certification.png"),
      "width": "170",
      "height": "170"
    }
  })]);
}];
export { render, staticRenderFns };